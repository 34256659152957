// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import "firebase/firestore";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDiBr3sLqnJVzj70g1FXPWo8nCznzsmcNc",
    authDomain: "cd-lynparza-game.firebaseapp.com",
    databaseURL: "https://cd-lynparza-game-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "cd-lynparza-game",
    storageBucket: "cd-lynparza-game.appspot.com",
    messagingSenderId: "1009249614304",
    appId: "1:1009249614304:web:82d5a03b99dd81d2b0d385",
    measurementId: "G-PR293M2WKY"
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);
// export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firebaseDB = firebaseApp.firestore();
export const collection = firebaseDB.collection("astrazenecca-game");
