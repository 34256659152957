import classes from './finished.module.css'
const Finished = (props) => {
    function backToTeamsHandler(){
        props.onClose()
    }
    return (<div className={classes.finishedContainer}>

        <div className={classes.finishedMessage}>
            <div onClick={()=>backToTeamsHandler()} className={classes.closeButton}/>
        </div>
        {/* <div className={classes.rowContainer}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.team1} />
                <div >points</div>
            </div>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.team2} />
                <div >points</div>
            </div>
        </div>
        <div className={classes.rowContainer}> 
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.team3} />
                <div style={{ marginTop: '20vh' }}>points</div>

            </div>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={classes.team4} />
                <div style={{ marginTop: '20vh' }}>points</div>


            </div>
        </div>*/}
    </div>)
}
export default Finished