import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import "./droppablesStyles.css";
import "./drop.css";
// transplant - Dialysis - Anemia - Hyperkalemiab - ASCVD - HF
//Anemia - Dialysis - Transplant - HyperKalemiab - ASCVD - HF
let options = [
  { content: "≥90", id: "1", show: false },
  { content: "60–89", id: "2", show: false },
  { content: "45–59", id: "3", show: false },
  { content: "30–44", id: "4", show: false },
  { content: "15–29", id: "5", show: false },
  { content: "<15", id: "6", show: false },
  { content: "<30", id: "7", show: false },
  { content: "30–300", id: "8", show: false },
  { content: ">300", id: "9", show: false },
  { content: "Anemia", id: "10", show: false },
  { content: "Dialysis", id: "11", show: false },
  { content: "Transplant", id: "12", show: false },
  { content: "Hyperkalemia", id: "13", show: false },
  { content: "ASCVD", id: "14", show: false },
  { content: "HF", id: "15", show: false },
];
let chart = [
  { content: "Transplant", id: "16" },
  { content: "Dialysis", id: "17" },
  { content: "Anemia", id: "18" },
  { content: "Hyperkalemia", id: "19" },
  { content: "ASCVD", id: "20" },
  { content: "HF", id: "21" },
];
let arrow1 = [
  { content: "≥90", id: "22" },
  { content: "60–89", id: "23" },
  { content: "45–59", id: "24" },
  { content: "30–44", id: "25" },
  { content: "15–29", id: "26" },
  { content: "<15", id: "27" },
];
let arrow2 = [
  { content: "<30", id: "28" },
  { content: "30–300", id: "29" },
  { content: ">300", id: "30" },
];
let answeredOptions = [];
const Drop = (props) => {
  function shuffle(array) {
    let i = array.length;
    while (i--) {
      const ri = Math.floor(Math.random() * i);
      [array[i], array[ri]] = [array[ri], array[i]];
    }
    return array;
  }

  options = shuffle(options);

  useEffect(() => {
    // options = shuffle(options)
    // options = shuffle(options)
    // options = shuffle(options)
    // options = shuffle(options)
  }, []);
  const [answeredOptionsCount, setAnsweredOptionsCount] = useState(0);
  const [WrongTriesCount, setWrongTriesCount] = useState(0);
  useEffect(() => {
    console.log(answeredOptionsCount);

    if (answeredOptionsCount >= 15) {
      // alert('done')
      props.onFinish(WrongTriesCount);
    }
  }, [answeredOptionsCount]);

  const [state, setState] = useState(options);
  const [chartState, setChartState] = useState(chart);
  const [arrow1State, setArrow1State] = useState(arrow1);
  const [arrow2State, setArrow2State] = useState(arrow2);
  function handleOnDragEnd(result) {
    const { source, destination } = result;
    if (!result.destination) return;
    console.log("result : ", result);
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;
    let srcObject = options.find((element) => element.id == sInd.toString());
    let arr1destinationObject = arrow1State.find(
      (element) => element.id == dInd.toString()
    );
    let arr2destinationObject = arrow2State.find(
      (element) => element.id == dInd.toString()
    );
    let chartdestinationObject = chartState.find(
      (element) => element.id == dInd.toString()
    );
    console.log(
      "s : ",
      srcObject?.content,
      "arr1d : ",
      arr1destinationObject?.content,
      "arr2d : ",
      arr2destinationObject?.content,
      "char-d : ",
      chartdestinationObject?.content
    );
    if (
      srcObject?.content == arr1destinationObject?.content ||
      srcObject?.content == arr2destinationObject?.content ||
      srcObject?.content == chartdestinationObject?.content
    ) {
      answeredOptions.push(srcObject.content);
      let temp = answeredOptionsCount + 1;
      setAnsweredOptionsCount(temp);
    } else {
      let temp = WrongTriesCount + 1;
      setWrongTriesCount(temp);
    }
  }
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 4 * 2,
    width: "fit-content",
    margin: `0 0 ${4}px 0`,
    borderRadius: 20,
    background: isDragging ? "rgba(0,155,190,0.3)" : "transparent",
    fontFamily: "luckiestGuy",
    ...draggableStyle,
  });
  const getChartItemStyle = (isDragging, draggableStyle) => ({
    backgroundColor: isDragging ? "rgba(100,055,190,0.9)" : "transparent",

    ...draggableStyle,
  });
  const getOptionsStyle = (isDraggingOver) => ({
    color: "black",
    width: "fit-content",
    fontSize: "1vw",
  });
  const getListStyle = (isDraggingOver) => ({
    color: "black",
    width: "fit-content",
    background: isDraggingOver
      ? "rgba(255,255,255,0.2)"
      : "rgba(255,255,255,0)",
  });

  const getChartStyle = (isDraggingOver) => ({
    color: "white",
    width: "fit-content",
    fontSize: "1.3vw",
    // fontFamily: 'luckiestGuy'

    background: isDraggingOver
      ? "rgba(255,255,255,0.2)"
      : "rgba(255,255,255,0)",
  });
  return (
    <div className={"container"}>
      <div className="reds" />
      <div className="topMessage">
        CKD is associated with a variety of comorbidities, the prevalence and
        severity of which increase with worsening CKD
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="optionsContainer">
          {state.map((el, ind) => {
            return (
              <Droppable key={ind} droppableId={`${el.id}`}>
                {(provided, snapshot) => (
                  <div
                    className={`o${ind + 1}`}
                    ref={provided.innerRef}
                    style={getOptionsStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Draggable
                      key={el.id}
                      draggableId={el.id}
                      index={parseInt(el.id)}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {/* <div className={`chart${parseInt(ind) + 1}`} /> */}
                          <div
                            style={{
                              // display: "flex",
                              // justifyContent: "flex-start",
                              width: "8vw",
                            }}
                          >
                            {!answeredOptions.includes(el.content)
                              ? el.content
                              : ""}
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </div>

        <div className={"chart"}>
          {chartState.map((el, ind) => {
            return (
              <Droppable key={ind} droppableId={`${el.id}`}>
                {(provided, snapshot) => {
                  let r = options.find(
                    (element, index) =>
                      element.content == element.content && element.show == true
                  );
                  // if (r) console.log(r);
                  return (
                    <div
                      className={`chart${ind + 1}`}
                      ref={provided.innerRef}
                      style={getChartStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      <Draggable
                        key={el.id}
                        draggableId={el.id}
                        index={parseInt(el.id)}
                      >
                        {(provided, snapshot) => (
                          <div
                            // className="optionsText"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getChartItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              style={{
                                // display: "flex",
                                // justifyContent: "flex-start",
                                width: "8vw",
                              }}
                            >
                              {/* {'chart' + parseInt(ind + 1)} */}
                              {/* {options[parseInt(el.id) - 1]?.show ? el.content : el.id} */}
                              {answeredOptions.includes(el.content)
                                ? el.content
                                : ""}
                            </div>
                          </div>
                        )}
                      </Draggable>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            );
          })}
        </div>
        <div className="arrows1">
          {arrow1State.map((el, ind) => {
            return (
              <Droppable key={ind} droppableId={`${el.id}`}>
                {(provided, snapshot) => (
                  <div
                    className={`topArrow${ind + 1}`}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Draggable
                      key={el.id}
                      draggableId={el.id}
                      index={parseInt(el.id)}
                    >
                      {(provided, snapshot) => (
                        <div
                          // className="optionsText"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getChartItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            style={{
                              // display: "flex",
                              // justifyContent: "flex-start",
                              width: "8vw",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "1.4vw",
                                marginLeft: "-20%",
                                color: "white",
                              }}
                            >
                              {/* {options[ind]?.show ? el.content : ''} */}
                              {answeredOptions.includes(el.content)
                                ? el.content
                                : ""}
                            </div>
                            {/* {'...'} */}
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </div>

        <div className="arrows2">
          {arrow2State.map((el, ind) => {
            return (
              <Droppable key={ind} droppableId={`${el.id}`}>
                {(provided, snapshot) => (
                  <div
                    className={`bottomArrow${ind + 1}`}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Draggable
                      key={el.id}
                      draggableId={el.id}
                      index={parseInt(el.id)}
                    >
                      {(provided, snapshot) => (
                        <div
                          // className="optionsText"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getChartItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            style={{
                              // display: "flex",
                              // justifyContent: "flex-start",
                              width: "8vw",
                              // backgroundColor: 'red'
                            }}
                          >
                            <div
                              style={{
                                fontSize: "70%",
                                marginLeft: "-20%",
                                color: "white",
                              }}
                            >
                              {answeredOptions.includes(el.content)
                                ? el.content
                                : ""}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </div>
      </DragDropContext>
    </div>
  );
};
export default Drop;
