import React, { useState } from "react";
import "./App.css";
import Finished from "./components/Finished";
import SelectTeam from "./components/SelectTeam";
import Drop from "./components/drop";
import { collection } from "./firebase/firebaseconfig";

function App() {
  const [teamSelected, setTeamSelected] = useState(false);
  const [finished, setFinished] = useState(false);
  function selectTeamHandler(team) {
    setTeamSelected(team);
  }

  async function finishedHandler(wrongTries) {
    const score = 1500 - wrongTries * 100;
    const teamRef = collection.doc("teams");
    const teams = await teamRef.get();
    let teamsData = teams.data();
    if (teamsData[`t${teamSelected}`] === undefined) {
      teamsData[`t${teamSelected}`] = { g3: [] };
    } else if (teamsData[`t${teamSelected}`].g3 === undefined) {
      teamsData[`t${teamSelected}`].g3 = [];
    }
    teamsData[`t${teamSelected}`].g3 = [
      ...teamsData[`t${teamSelected}`].g3,
      {
        score,
        timestamp: Date.now(),
      },
    ];
    await teamRef.set(teamsData);
    setFinished(true);
  }

  function closeFinishMessageHandler() {
    setFinished(false);
    setTeamSelected(0);
    window.location.reload();
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="draggables">
          {!teamSelected && !finished && (
            <SelectTeam onSelect={(team) => selectTeamHandler(team)} />
          )}
          {teamSelected && !finished && <Drop onFinish={finishedHandler} />}
          {finished && <Finished onClose={closeFinishMessageHandler} />}
        </div>
      </header>
    </div>
  );
}

export default App;
